import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Metatags from "../components/metatags"

import Navbar from "../components/navbar"
import Footer from "../components/footer"

import "react-quill/dist/quill.snow.css"
import "../css/multilogica-theme.scss"

import LogoWhite from "../assets/MultilogicaShop_8_slogan_branco.svg"

function GenericPublicTutorial({ data }) {
  const post = data.gatsbyappsync.listTutoriaisMultilogicas.items[0]
  // const topRef = useRef()

  return (
    <>
      <Metatags
        title={`Multilogica ${post.title}`}
        description="Tutoriais da Multilógica-shop"
        url="https://tutoriais.multilogica-shop.com"
        image="https://tutoriais.multilogica-shop.com/Multilogica-shop.png"
        keywords="eletrônica, arduino, micro:bit, raspberry pi, sparkfun, pololu"
      />
      <div style={{ display: "none" }}>
        <Navbar />
      </div>

      <Container fluid style={{ padding: "0" }}>
        <Row style={{ maxWidth: "100%", margin: 0 }}>
          <Col className="home" style={{ padding: 0 }}>
            <div style={{ position: "relative" }}>
              <GatsbyImage
                image={getImage(data.tutorialImage)}
                backgroundColor={false}
                alt="Tutorial Multiloógica"
                style={{
                  left: 0,
                  top: 0,
                }}
                className="bg-image"
              />
              <div className="header-text">
                <div style={{ width: "100%", textAlign: "center" }}>
                  <LogoWhite id="logo-multilogica" />

                  <h1>Tutorial</h1>

                  <a
                    href="https://multilogica-shop.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="transp-button">
                      Voltar para a loja &gt;
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="height-40" />
      <div className="height-40" />
      <Container>
        <Row>
          <Col className="tutorial-header-title-wrapper text-center">
            <div className="tutorial-header-title">
              <h2
                dangerouslySetInnerHTML={{
                  __html: post.title,
                }}
              />
              <p>
                {typeof post.publicationDate !== "undefined"
                  ? new Date(post.publicationDate).toLocaleString("pt-BR", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : null}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="height-40" />
      <Container className="tutorial-body">
        <Row>
          <Col sm={2} />
          <Col
            sm={8}
            className="ql-editor"
            style={{ resize: "none" }}
            dangerouslySetInnerHTML={{
              __html: post.body,
            }}
          />
        </Row>
      </Container>

      <div className="height-40" />
      <div className="height-40" />
      <Footer />
    </>
  )
}

export default GenericPublicTutorial

export const appSyncQuery = graphql`
  query ($id: String!) {
    gatsbyappsync {
      listTutoriaisMultilogicas(limit: 1000, filter: { id: { eq: $id } }) {
        items {
          publicationDate
          picture
          body
          title
          teaser
          slug
          id
        }
      }
    }

    tutorialImage: file(relativePath: { eq: "Tutorial_Testeira_1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
  }
`
